import { Link } from "react-router-dom";

export default function LandingSlogan() {
  return (
    <div className="slogan">
      <div className="slogan__text">
        <div className="slogan__text__title">
          Hulgi vedelkütuse hanked
        </div>
        <div className="slogan__text__content">
          Oleme Eesti esimene vedelkütuse hankeplatvorm.
          Vahendame hulgi vedelkütuse hankeid. Kui soovid osta
          bensiini 95, 98, diislikütust, erimärgistatud diislikütust või 
          biokütust, loo kasutaja ja korralda hange tasuta!
        </div>
        <Link
          to="/oil-requests/create" className="slogan__text__button">
          Korralda hange
        </Link>
      </div>
      <div className="slogan__img">
        <img src="images/svg/landing-page.svg" alt="Säästa aega" />
      </div>
    </div>
  );
}
