
export default function Tutorial() {
    return (
        <div className="tutorial">

            <div className="tutorial__title">
                Kuidas luua vedelkütuse hanget?
            </div>
            <div>
                <div className="diagram-desktop">
                    <ul className="diagram-container">
                        <li className="dot">
                            <div className="tutorial__text-container">
                                <div className="tutorial__text-title">
                                    Loo konto
                                </div>
                                <div className="tutorial__text-content">
                                    Liitu hankeplatvormiga<br></br> ja loo kasutaja.
                                </div>
                            </div>
                        </li>
                        <li className="dot">
                            <div className="tutorial__text-container">
                                <div className="tutorial__text-title">
                                    Lisa hange
                                </div>
                                <div className="tutorial__text-content">
                                    Lisa hange mõne lihtsa<br></br> sammuga.
                                </div>
                            </div></li>
                        <li className="dot">
                            <div className="tutorial__text-container">
                                <div className="tutorial__text-title">
                                    Oota pakkumisi
                                </div>
                                <div className="tutorial__text-content">
                                    Peale hanke lisamist on hange<br></br> aktiivne
                                    ja pakkujad saavad <br></br>Teile pakkumisi teha.
                                </div>
                            </div>
                        </li>
                        <li className="dot">
                            <div className="tutorial__text-container">
                                <div className="tutorial__text-title">
                                    Vali võitja
                                </div>
                                <div className="tutorial__text-content">
                                    Võitja valimine.
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="diagram-mobile">
                    <ul className="diagram-container">
                        <li className="dot">
                            <div className="tutorial__text-container">
                                <div className="tutorial__text-title">
                                    Loo konto
                                </div>
                                <div className="tutorial__text-content">
                                    Liitu hankeplatvormiga ja loo kasutaja.
                                </div>
                            </div>
                        </li>
                        <li className="dot">
                            <div className="tutorial__text-container">
                                <div className="tutorial__text-title">
                                    Lisa hange
                                </div>
                                <div className="tutorial__text-content">
                                    Lisa hange mõne lihtsa sammuga.
                                </div>
                            </div></li>
                        <li className="dot">
                            <div className="tutorial__text-container">
                                <div className="tutorial__text-title">
                                    Oota pakkumisi
                                </div>
                                <div className="tutorial__text-content">
                                    Peale hanke lisamist on hange aktiivne
                                    ja pakkujad saavad Teile pakkumisi teha.
                                </div>
                            </div>
                        </li>
                        <li className="dot">
                            <div className="tutorial__text-container">
                                <div className="tutorial__text-title">
                                    Vali võitja
                                </div>
                                <div className="tutorial__text-content">
                                    Võitja valimine.
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}
