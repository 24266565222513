import React, { Component } from "react";
import { Helmet } from "react-helmet";

import { PictureLayoutForm } from "../../common/Layout";
import Loader from "../../common/Loader";
import SignUpForm from "./sub/SignUpForm";
import SignUpComplete from "./sub/SignUpComplete";
import { Link } from "react-router-dom";

const Steps = Object.freeze({
  StartRegistration: "StartRegistration",
  CompleteRegistration: "CompleteRegistration",
});

class SignUpPage extends Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      step: Steps.StartRegistration,
      loading: false,
    };
  }

  nextStep = () => {
    switch (this.state.step) {
      case Steps.StartRegistration:
        this.setState({ step: Steps.CompleteRegistration });
        break;
      default:
        this.setState({ step: Steps.StartRegistration });
    }
  };

  step = () => {
    const { step } = this.state;
    switch (step) {
      case Steps.CompleteRegistration:
        return <SignUpComplete />;
      default:
        return <SignUpForm nextStep={this.nextStep} />;
    }
  };

  render() {
    return (
      <PictureLayoutForm>
        <Helmet>
          <title>Turgoil - Loo konto</title>
          <meta
            name="description"
            content="Logi sisse või loo kasutaja, et korraldada vedelkütuse hange juba täna."
          />
        </Helmet>
        <div className="createAccount">
          <div className="createAccount__title">Loo konto</div>
          <div >
            <span className="createAccount__question">Sul on konto olemas? </span>
            <Link to="/signin">
              <a href="/signin" className="createAccount__link">
                Logi sisse
              </a>
            </Link>
          </div>
        </div>
        {!this.state.loading && this.step()}
        {this.state.loading && <Loader />}
      </PictureLayoutForm>
    );
  }
}

export default SignUpPage;
