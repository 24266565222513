import React from "react";

class Logos extends React.PureComponent {
  render() {
    return (
        <div className="logos">
            <div>
              <img
                className="logos__icon_jetoil"
                src="images/jpg/Jetoil-logo.jpg"
                alt="Jetoil logo"
              />
            </div>
            <div>
              <img
                className="logos__icon_markoil"
                src="images/jpg/Markoil-logo.jpg"
                alt="Markoil logo"
              />
            </div>
            <div >
              <img
                className="logos__icon_neste"
                src="images/svg/Neste-logo.svg"
                alt="Neste logo"
              />
            </div>
            <div>
              <img
                className="logos__icon_terminal"
                src="images/png/Terminal-logo.png"
                alt="Terminal logo"
              />
            </div>
        </div>
    );
  }
}

export default Logos;