import React from "react";
import { Helmet } from "react-helmet";
import Footer from "../../common/Footer";
import Logos from "../../common/Logos";
import HeaderLight from "../../common/HeaderLight";
import Tutorial from "./sub/Tutorial";
import Section from "../../common/Section";
import LandingSlogan from "./sub/LandingSlogan";
import Benefits from "./sub/Benefits";
import Counters from "./sub/Counters";

type State = {
  title: string;
  content: string;
};

class LandingPage extends React.PureComponent<any, State> {
  constructor(props) {
    super(props);

    this.state = {
      title: "",
      content: "",
    };
  }

  render() {
    const { title, content } = this.state;

    return (
      <div className="landing-page">
        <Helmet>
          <title>
            Turgoil - vedelkütuse hanked: bensiin 95, bensiin 98, diislikütus,
            erimärgistusega diislikütus
          </title>
          <meta
            name="description"
            content="Soovid osta diislikütust, bensiin 95, bensiin 98, 
						erimärgistusega diislikütust või biokütust? Korralda vedelkütuse hange
						Turgoilis, Eesti vedelkütuse hankeid vahendav veebikeskkond."
          />
        </Helmet>
        <div className="landing-page">
          <HeaderLight location="landing-page"/>
          <Section backgroundColor="#1a273d33">
            <LandingSlogan />
            <Benefits />
          </Section>
          <hr />
          <Section backgroundColor="#fff">
            <Counters />
          </Section>
          <hr />
          <Section backgroundColor="#fff">
            <Tutorial />
          </Section>
          <Footer />
        </div>
      </div>
    );
  }
}

export default LandingPage;


