import React from "react";
import { Link } from "react-router-dom";
import Logos from "../Logos";

const Footer = () => {
  return (
    <div style={{ marginTop: 'auto' }}>
      <hr />
      <Logos />
      <div className="footer-wrapper">
        <div className="footer">
          <div className="footer__columns">
            <div className="footer__column">
              <div className="footer__title">Uuri lähemalt</div>
              <div className="footer__links-container">
                <div>
                  <Link className="footer__link" to="/instructions">
                    Kasutusjuhend
                  </Link>
                </div>
                <div>
                  <Link className="footer__link" to="/frequently-asked-questions">
                    Korduvad küsimused
                  </Link>
                </div>
                <div>
                  <Link className="footer__link" to="/advertisement">
                    Reklaam
                  </Link>
                </div>
              </div>
            </div>
            <div className="footer__column">
              <div className="footer__title">Dokumendid</div>
              <div className="footer__links-container">
                <div>
                  <Link className="footer__link" to="/terms-and-conditions">
                    Kasutustingimused
                  </Link>
                </div>
                <div>
                  <Link className="footer__link" to="/privacy-policy">
                    Privaatsustingimused
                  </Link>
                </div>
              </div>
            </div>
            <div className="footer__column">
              <div className="footer__title">Kontakt</div>
              <div className="footer__links-container">
                <div>
                  <a className="footer__link-email" href="mailto:info@turgoil.com">info@turgoil.com</a>
                </div>
                <div className="footer__text">
                  TI-haldus OÜ
                </div>
                <div className="footer__text">
                  Registrikood: 14121440
                </div>
              </div>
            </div>
          </div>
          <div>
            <Link to="/">
              <img
                className="footer__logo"
                src="images/svg/turgoil-logo.svg"
                alt="Turgoil logo"
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;