
const Section = ({ children, backgroundColor  }) => {
    return (
        <div className="section-wrapper" style={{ backgroundColor }}>
            <div className="section">
                {children}
            </div>
        </div>
    );
};

export default Section;