export default function Counters() {
        return (
            <div className="counters">
                <div className="counters__content">
                    <div className="counters__counter">
                        <div className="counters__title">Üldsumma</div>
                        <div className="counters__value">x €</div>
                    </div>
                    <div className="counters__divider">
                        <hr />
                    </div>
                    <div className="counters__counter">
                        <div className="counters__title">Vahendatud liitrid</div>
                        <div className="counters__value">x l</div>
                    </div>
                    <div className="counters__divider">
                        <hr />
                    </div>
                    <div className="counters__counter">
                        <div className="counters__title">Hangete arv</div>
                        <div className="counters__value">x</div>
                    </div>
                </div>
            </div>
        );
    
}