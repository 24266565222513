
export default function Benefits() {
    return (
        <div className="benefits">
            <div className="card benefit">
                <div>
                    <img
                        src="images/svg/save-time.svg"
                        alt="Save time"
                    />
                </div>
                <div className="benefit__text">
                    <div className="benefit__title">
                        Säästa aega
                    </div>
                    <div className="benefit__content">
                        Vedelkütuse hanke korraldamine on imelihtne ja
                        jõuab pakkujateni mõne sekundi jooksul.
                    </div>
                </div>
            </div>
            <div className="card benefit">
                <div>
                    <img
                        src="images/svg/reliable.svg"
                        alt="Reliable"
                    />
                </div>
                <div className="benefit__text">
                    <div className="benefit__title">
                        Usaldusväärne
                    </div>
                    <div className="benefit__content">
                        Pakkumisi saavad teha ainult ettevõtted,
                        kes on tegutsenud Eesti kütuseturul mitmeid aastaid.
                    </div>
                </div>
            </div>
            <div className="card benefit">
                <div>
                    <img
                        src="images/svg/save-money.svg"
                        alt="Save money"
                    />
                </div>
                <div className="benefit__text">
                    <div className="benefit__title">
                        Säästa raha
                    </div>
                    <div className="benefit__content">
                        Vedelkütuse hankele tehakse mitmeid pakkumisi,
                        mille seast saab hanke korraldaja valida sobivaima pakkumise.
                    </div>
                </div>
            </div>
        </div>
    );
}
