import React, { PureComponent } from "react";
import { Button, Form } from "react-bootstrap";
import Loader from "../../../common/Loader";
import { validateEmail } from "../../../utils/regex";
import { startUserRecovery } from "../../../services/turgoil-api";
import { connect } from "react-redux";
import * as toastActions from "../../../state/toasts";
import { ToastType } from "../../../common/Toasts";

type Props = {
  showToast: typeof toastActions.showToast;
};

type State = {
  recoveryProcess: boolean;
  error: string;
  email: string;
  emailError: string;
  loading: boolean;
};

class StartRecovery extends PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      recoveryProcess: false,
      error: "",
      email: "",
      emailError: "",
      loading: false,
    };
  }

  handleChange = (e) => {
    this.setState({
      email: e.target.value,
      emailError: "",
      error: "",
    });
  };

  validate = () => {
    const { email } = this.state;

    if (!validateEmail(email)) {
      this.setState({
        emailError:
          "See e-posti aadress ei ole korrektne. Korrektne on näiteks info@turgoil.com.",
      });
      return false;
    }

    return true;
  };

  startUserRecoveryProcess = (e) => {
    const { showToast } = this.props;
    e.preventDefault();
    if (!this.validate()) {
      return;
    }

    const { email } = this.state;
    this.setState({
      loading: true,
      error: "",
    });

    startUserRecovery(email)
      .then(() => {
        this.setState({
          recoveryProcess: true,
          loading: false,
        });
      })
      .catch((err) => {
        showToast({
          title: "Päring ebaõnnestus.",
          text: err.message,
          type: ToastType.Error,
        });
        this.setState({
          loading: false,
          error: err.message,
        });
      });
  };

  render() {
    const { error, email, emailError, loading, recoveryProcess } = this.state;

    return (
      <>
        {loading && <Loader />}
        {!loading && (
          <>
            {recoveryProcess ? (
              <div className="password-recovery-email-sent">
                <p>
                  Teile on saadetud parooli taastamise juhised e-posti
                  aadressile.
                </p>
                <div className="password-recovery-email-sent__picture" />
              </div>
            ) : (
              <>
                <Form onSubmit={this.startUserRecoveryProcess}>
                  <Form.Group className="mb-2">
                    <Form.Label htmlFor="email">E-posti aadress</Form.Label>
                    <Form.Control
                      name="email"
                      id="email"
                      value={email}
                      onChange={this.handleChange}
                      isInvalid={!!emailError.length}
                    />
                    <Form.Control.Feedback type="invalid">
                      {emailError}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Button type="submit" className="recovery__button">
                    Taasta
                  </Button>
                </Form>
              </>
            )}
          </>
        )}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    showToast: (toast) => dispatch(toastActions.showToast(toast)),
  };
}

export default connect(mapDispatchToProps)(StartRecovery);
