import React from "react";
import { Helmet } from "react-helmet";
import { Link, useLocation } from "react-router-dom";

import { PictureLayoutForm } from "../../common/Layout";
import StartRecovery from "./sub/StartRecovery";
import Recover from "./sub/Recover";

const AccountRecoveryPage = (): React.ReactElement => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);

  return (
    <PictureLayoutForm>
      <Helmet>
        <title>
          Turgoil - Salasõna taastamine / Unustasid salasõna / Ei saa sisse
          logida
        </title>
        <meta
          name="description"
          content="Selleks, et taastada oma salasõna sisesta oma e-posti aadress."
        />
      </Helmet>
      
      <div className="recovery">
        <div className="recovery__title">Salasõna taastamine</div>
        <div>
          <span className="recovery__question">Sul on konto olemas? </span>
          <Link to="/signin">
            <a href="/signin" className="recovery__link">
            Logi sisse
            </a>
          </Link>
        </div>
      </div>

      {!query.get("recoveryCode") && <StartRecovery />}
      {query.get("recoveryCode") && <Recover />}
    </PictureLayoutForm>
  );
};

export default AccountRecoveryPage;
