import React, { PureComponent } from "react";
import { Button, Form } from "react-bootstrap";
import Loader from "../../../common/Loader";
import * as toastActions from "../../../state/toasts";

import {
  completeUserRecovery,
  getUserRecoveryStatus,
} from "../../../services/turgoil-api";
import { RouteComponentProps, withRouter } from "../../../common/withRouter";
import { connect } from "react-redux";
import { ToastType } from "../../../common/Toasts";

type MapDispatchToProps = {
  showToast: typeof toastActions.showToast;
};

type Props = RouteComponentProps & MapDispatchToProps;

class Recover extends PureComponent<Props, any> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      recoverySuccess: false,
      password: "",
      passwordError: "",
      passwordAgain: "",
      isRecoveryLinkValid: false,
    };
  }

  componentDidMount() {
    const { location, showToast } = this.props;
    const query = new URLSearchParams(location.search);

    const recoveryCode = query.get("recoveryCode");
    if (recoveryCode) {
      getUserRecoveryStatus(recoveryCode)
        .then(() => {
          this.setState({
            loading: false,
            isRecoveryLinkValid: true,
          });
        })
        .catch(() => {
          showToast({
            title: "Päring ebaõnnestus.",
            text: "See link on aegunud ning sellega ei saa parooli muuta. Parooli muutmiseks saatke uus sõnum.",
            type: ToastType.Error,
          });
          this.setState({
            loading: false,
            isRecoveryLinkValid: false,
          });
        });
    }
  }

  handleChange = (e: any) => {
    this.setState({
      [e.target.name]: e.target.value,
      passwordError: "",
    });
  };

  validatePassword = () => {
    const { password, passwordAgain } = this.state;

    if (password.length < 5) {
      this.setState({
        passwordError: "Parooli pikkus peab olema vähemalt 5 tähemärki. ",
      });
      return false;
    }

    if (password !== passwordAgain) {
      this.setState({
        passwordError: "Parool ja parool uuesti peavad kattuma.",
      });
      return false;
    }

    return true;
  };

  handlePasswordSubmit = (e: any) => {
    const { showToast } = this.props;
    e.preventDefault();
    if (!this.validatePassword()) {
      return;
    }

    const { password } = this.state;
    const { location } = this.props;
    const query = new URLSearchParams(location.search);

    const recoveryCode = query.get("recoveryCode");
    this.setState({
      loading: true,
    });
    completeUserRecovery(recoveryCode || "", password)
      .then(() => {
        this.setState({
          recoverySuccess: true,
          loading: false,
        });

        showToast({
          title: "Päring õnnestus.",
          text: "Teie parool on edukalt muudetud!",
          type: ToastType.Success,
        });
      })
      .catch((err) => {
        showToast({
          title: "Päring ebaõnnestus.",
          text: err.message,
          type: ToastType.Error,
        });

        this.setState({
          loading: false,
        });
      });
  };

  render() {
    const { isRecoveryLinkValid, passwordError, loading } = this.state;

    return (
      <>
        {loading && <Loader />}
        {!loading && (
          <>
            {!isRecoveryLinkValid ? (
              <></>
            ) : (
              <>
                {!this.state.recoverySuccess && (
                  <>
                    <Form onSubmit={this.handlePasswordSubmit}>
                      <Form.Group className="mb-2">
                        <Form.Label htmlFor="password">Parool</Form.Label>
                        <Form.Control
                          name="password"
                          type="password"
                          id="password"
                          value={this.state.password}
                          onChange={this.handleChange}
                          isInvalid={passwordError.length}
                        />
                        <Form.Control.Feedback type="invalid">
                          {passwordError}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group className="mb-2">
                        <Form.Label htmlFor="password">
                          Parool uuesti
                        </Form.Label>
                        <Form.Control
                          name="passwordAgain"
                          type="password"
                          id="passwordAgain"
                          value={this.state.passwordAgain}
                          onChange={this.handleChange}
                        />
                      </Form.Group>
                      <Button type="submit" className="recovery__button">
                        Muuda parool
                      </Button>
                    </Form>
                  </>
                )}
              </>
            )}
          </>
        )}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    showToast: (toast) => dispatch(toastActions.showToast(toast)),
  };
}

export default withRouter(connect(null, mapDispatchToProps)(Recover));
